const Steps = {
  uk: [
    { item: 'SourceFieldSet', label: 'Address' },
    // { item: 'CourierVisitDate', label: 'Date' },
    { item: 'ParcelsCount', label: 'Count' },
    // { item: 'Items', label: 'Items' },
    // { item: 'CollectionType', label: 'Service' },
    { item: 'TermsAndConditions', label: 'Terms & Conditions' }
  ],
  ie: [
    { item: 'SourceFieldSet', label: 'Address' },
    { item: 'CourierVisitDate', label: 'Date/Count/Service' },
    // { item: 'ParcelsCount', label: 'Count' },
    { item: 'Items', label: 'Items' },
    // { item: 'CollectionType', label: 'Service' },
    { item: 'TermsAndConditions', label: 'Terms & Conditions' }
  ],
  se: [
    { item: 'SourceFieldSet', label: 'Address' },
    { item: 'CourierVisitDate', label: 'Date/Count/Service' },
    // { item: 'ParcelsCount', label: 'Count' },
    { item: 'Items', label: 'Items' },
    // { item: 'CollectionType', label: 'Service' },
    { item: 'TermsAndConditions', label: 'Terms & Conditions' }
  ],
  ge: [
    { item: 'SourceFieldSet', label: 'Address' },
    { item: 'CourierVisitDate', label: 'Date/Count/Service' },
    // { item: 'ParcelsCount', label: 'Count' },
    { item: 'Items', label: 'Items' },
    // { item: 'CollectionType', label: 'Service' },
    { item: 'TermsAndConditions', label: 'Terms & Conditions' }
  ]
};

export default Steps;