const countryListAllIsoData = [
  { value: "AF", "code3": "AFG", label: "Afghanistan", "number": "004" },
  { value: "AL", "code3": "ALB", label: "Albania", "number": "008" },
  { value: "DZ", "code3": "DZA", label: "Algeria", "number": "012" },
  { value: "AS", "code3": "ASM", label: "American Samoa", "number": "016" },
  { value: "AD", "code3": "AND", label: "Andorra", "number": "020" },
  { value: "AO", "code3": "AGO", label: "Angola", "number": "024" },
  { value: "AI", "code3": "AIA", label: "Anguilla", "number": "660" },
  { value: "AQ", "code3": "ATA", label: "Antarctica", "number": "010" },
  { value: "AG", "code3": "ATG", label: "Antigua and Barbuda", "number": "028" },
  { value: "AR", "code3": "ARG", label: "Argentina", "number": "032" },
  { value: "AM", "code3": "ARM", label: "Armenia", "number": "051" },
  { value: "AW", "code3": "ABW", label: "Aruba", "number": "533" },
  { value: "AU", "code3": "AUS", label: "Australia", "number": "036" },
  { value: "AT", "code3": "AUT", label: "Austria", "number": "040" },
  { value: "AZ", "code3": "AZE", label: "Azerbaijan", "number": "031" },
  { value: "BS", "code3": "BHS", label: "Bahamas (the)", "number": "044" },
  { value: "BH", "code3": "BHR", label: "Bahrain", "number": "048" },
  { value: "BD", "code3": "BGD", label: "Bangladesh", "number": "050" },
  { value: "BB", "code3": "BRB", label: "Barbados", "number": "052" },
  { value: "BY", "code3": "BLR", label: "Belarus", "number": "112" },
  { value: "BE", "code3": "BEL", label: "Belgium", "number": "056" },
  { value: "BZ", "code3": "BLZ", label: "Belize", "number": "084" },
  { value: "BJ", "code3": "BEN", label: "Benin", "number": "204" },
  { value: "BM", "code3": "BMU", label: "Bermuda", "number": "060" },
  { value: "BT", "code3": "BTN", label: "Bhutan", "number": "064" },
  { value: "BO", "code3": "BOL", label: "Bolivia (Plurinational State of)", "number": "068" },
  { value: "BQ", "code3": "BES", label: "Bonaire, Sint Eustatius and Saba", "number": "535" },
  { value: "BA", "code3": "BIH", label: "Bosnia and Herzegovina", "number": "070" },
  { value: "BW", "code3": "BWA", label: "Botswana", "number": "072" },
  { value: "BV", "code3": "BVT", label: "Bouvet Island", "number": "074" },
  { value: "BR", "code3": "BRA", label: "Brazil", "number": "076" },
  { value: "IO", "code3": "IOT", label: "British Indian Ocean Territory (the)", "number": "086" },
  { value: "BN", "code3": "BRN", label: "Brunei Darussalam", "number": "096" },
  { value: "BG", "code3": "BGR", label: "Bulgaria", "number": "100" },
  { value: "BF", "code3": "BFA", label: "Burkina Faso", "number": "854" },
  { value: "BI", "code3": "BDI", label: "Burundi", "number": "108" },
  { value: "CV", "code3": "CPV", label: "Cabo Verde", "number": "132" },
  { value: "KH", "code3": "KHM", label: "Cambodia", "number": "116" },
  { value: "CM", "code3": "CMR", label: "Cameroon", "number": "120" },
  { value: "CA", "code3": "CAN", label: "Canada", "number": "124" },
  { value: "KY", "code3": "CYM", label: "Cayman Islands (the)", "number": "136" },
  { value: "CF", "code3": "CAF", label: "Central African Republic (the)", "number": "140" },
  { value: "TD", "code3": "TCD", label: "Chad", "number": "148" },
  { value: "CL", "code3": "CHL", label: "Chile", "number": "152" },
  { value: "CN", "code3": "CHN", label: "China", "number": "156" },
  { value: "CX", "code3": "CXR", label: "Christmas Island", "number": "162" },
  { value: "CC", "code3": "CCK", label: "Cocos (Keeling) Islands (the)", "number": "166" },
  { value: "CO", "code3": "COL", label: "Colombia", "number": "170" },
  { value: "KM", "code3": "COM", label: "Comoros (the)", "number": "174" },
  { value: "CD", "code3": "COD", label: "Congo (the Democratic Republic of the)", "number": "180" },
  { value: "CG", "code3": "COG", label: "Congo (the)", "number": "178" },
  { value: "CK", "code3": "COK", label: "Cook Islands (the)", "number": "184" },
  { value: "CR", "code3": "CRI", label: "Costa Rica", "number": "188" },
  { value: "HR", "code3": "HRV", label: "Croatia", "number": "191" },
  { value: "CU", "code3": "CUB", label: "Cuba", "number": "192" },
  { value: "CW", "code3": "CUW", label: "Curaçao", "number": "531" },
  { value: "CY", "code3": "CYP", label: "Cyprus", "number": "196" },
  { value: "CZ", "code3": "CZE", label: "Czechia", "number": "203" },
  { value: "CI", "code3": "CIV", label: "Côte d'Ivoire", "number": "384" },
  { value: "DK", "code3": "DNK", label: "Denmark", "number": "208" },
  { value: "DJ", "code3": "DJI", label: "Djibouti", "number": "262" },
  { value: "DM", "code3": "DMA", label: "Dominica", "number": "212" },
  { value: "DO", "code3": "DOM", label: "Dominican Republic (the)", "number": "214" },
  { value: "EC", "code3": "ECU", label: "Ecuador", "number": "218" },
  { value: "EG", "code3": "EGY", label: "Egypt", "number": "818" },
  { value: "SV", "code3": "SLV", label: "El Salvador", "number": "222" },
  { value: "GQ", "code3": "GNQ", label: "Equatorial Guinea", "number": "226" },
  { value: "ER", "code3": "ERI", label: "Eritrea", "number": "232" },
  { value: "EE", "code3": "EST", label: "Estonia", "number": "233" },
  { value: "SZ", "code3": "SWZ", label: "Eswatini", "number": "748" },
  { value: "ET", "code3": "ETH", label: "Ethiopia", "number": "231" },
  { value: "FK", "code3": "FLK", label: "Falkland Islands (the) [Malvinas]", "number": "238" },
  { value: "FO", "code3": "FRO", label: "Faroe Islands (the)", "number": "234" },
  { value: "FJ", "code3": "FJI", label: "Fiji", "number": "242" },
  { value: "FI", "code3": "FIN", label: "Finland", "number": "246" },
  { value: "FR", "code3": "FRA", label: "France", "number": "250" },
  { value: "GF", "code3": "GUF", label: "French Guiana", "number": "254" },
  { value: "PF", "code3": "PYF", label: "French Polynesia", "number": "258" },
  { value: "TF", "code3": "ATF", label: "French Southern Territories (the)", "number": "260" },
  { value: "GA", "code3": "GAB", label: "Gabon", "number": "266" },
  { value: "GM", "code3": "GMB", label: "Gambia (the)", "number": "270" },
  { value: "GE", "code3": "GEO", label: "Georgia", "number": "268" },
  { value: "DE", "code3": "DEU", label: "Germany", "number": "276" },
  { value: "GH", "code3": "GHA", label: "Ghana", "number": "288" },
  { value: "GI", "code3": "GIB", label: "Gibraltar", "number": "292" },
  { value: "GR", "code3": "GRC", label: "Greece", "number": "300" },
  { value: "GL", "code3": "GRL", label: "Greenland", "number": "304" },
  { value: "GD", "code3": "GRD", label: "Grenada", "number": "308" },
  { value: "GP", "code3": "GLP", label: "Guadeloupe", "number": "312" },
  { value: "GU", "code3": "GUM", label: "Guam", "number": "316" },
  { value: "GT", "code3": "GTM", label: "Guatemala", "number": "320" },
  { value: "GG", "code3": "GGY", label: "Guernsey", "number": "831" },
  { value: "GN", "code3": "GIN", label: "Guinea", "number": "324" },
  { value: "GW", "code3": "GNB", label: "Guinea-Bissau", "number": "624" },
  { value: "GY", "code3": "GUY", label: "Guyana", "number": "328" },
  { value: "HT", "code3": "HTI", label: "Haiti", "number": "332" },
  { value: "HM", "code3": "HMD", label: "Heard Island and McDonald Islands", "number": "334" },
  { value: "VA", "code3": "VAT", label: "Holy See (the)", "number": "336" },
  { value: "HN", "code3": "HND", label: "Honduras", "number": "340" },
  { value: "HK", "code3": "HKG", label: "Hong Kong", "number": "344" },
  { value: "HU", "code3": "HUN", label: "Hungary", "number": "348" },
  { value: "IS", "code3": "ISL", label: "Iceland", "number": "352" },
  { value: "IN", "code3": "IND", label: "India", "number": "356" },
  { value: "ID", "code3": "IDN", label: "Indonesia", "number": "360" },
  { value: "IR", "code3": "IRN", label: "Iran (Islamic Republic of)", "number": "364" },
  { value: "IQ", "code3": "IRQ", label: "Iraq", "number": "368" },
  { value: "IE", "code3": "IRL", label: "Ireland", "number": "372" },
  { value: "IM", "code3": "IMN", label: "Isle of Man", "number": "833" },
  { value: "IL", "code3": "ISR", label: "Israel", "number": "376" },
  { value: "IT", "code3": "ITA", label: "Italy", "number": "380" },
  { value: "JM", "code3": "JAM", label: "Jamaica", "number": "388" },
  { value: "JP", "code3": "JPN", label: "Japan", "number": "392" },
  { value: "JE", "code3": "JEY", label: "Jersey", "number": "832" },
  { value: "JO", "code3": "JOR", label: "Jordan", "number": "400" },
  { value: "KZ", "code3": "KAZ", label: "Kazakhstan", "number": "398" },
  { value: "KE", "code3": "KEN", label: "Kenya", "number": "404" },
  { value: "KI", "code3": "KIR", label: "Kiribati", "number": "296" },
  { value: "KP", "code3": "PRK", label: "Korea (the Democratic People's Republic of)", "number": "408" },
  { value: "KR", "code3": "KOR", label: "Korea (the Republic of)", "number": "410" },
  { value: "KW", "code3": "KWT", label: "Kuwait", "number": "414" },
  { value: "KG", "code3": "KGZ", label: "Kyrgyzstan", "number": "417" },
  { value: "LA", "code3": "LAO", label: "Lao People's Democratic Republic (the)", "number": "418" },
  { value: "LV", "code3": "LVA", label: "Latvia", "number": "428" },
  { value: "LB", "code3": "LBN", label: "Lebanon", "number": "422" },
  { value: "LS", "code3": "LSO", label: "Lesotho", "number": "426" },
  { value: "LR", "code3": "LBR", label: "Liberia", "number": "430" },
  { value: "LY", "code3": "LBY", label: "Libya", "number": "434" },
  { value: "LI", "code3": "LIE", label: "Liechtenstein", "number": "438" },
  { value: "LT", "code3": "LTU", label: "Lithuania", "number": "440" },
  { value: "LU", "code3": "LUX", label: "Luxembourg", "number": "442" },
  { value: "MO", "code3": "MAC", label: "Macao", "number": "446" },
  { value: "MG", "code3": "MDG", label: "Madagascar", "number": "450" },
  { value: "MW", "code3": "MWI", label: "Malawi", "number": "454" },
  { value: "MY", "code3": "MYS", label: "Malaysia", "number": "458" },
  { value: "MV", "code3": "MDV", label: "Maldives", "number": "462" },
  { value: "ML", "code3": "MLI", label: "Mali", "number": "466" },
  { value: "MT", "code3": "MLT", label: "Malta", "number": "470" },
  { value: "MH", "code3": "MHL", label: "Marshall Islands (the)", "number": "584" },
  { value: "MQ", "code3": "MTQ", label: "Martinique", "number": "474" },
  { value: "MR", "code3": "MRT", label: "Mauritania", "number": "478" },
  { value: "MU", "code3": "MUS", label: "Mauritius", "number": "480" },
  { value: "YT", "code3": "MYT", label: "Mayotte", "number": "175" },
  { value: "MX", "code3": "MEX", label: "Mexico", "number": "484" },
  { value: "FM", "code3": "FSM", label: "Micronesia (Federated States of)", "number": "583" },
  { value: "MD", "code3": "MDA", label: "Moldova (the Republic of)", "number": "498" },
  { value: "MC", "code3": "MCO", label: "Monaco", "number": "492" },
  { value: "MN", "code3": "MNG", label: "Mongolia", "number": "496" },
  { value: "ME", "code3": "MNE", label: "Montenegro", "number": "499" },
  { value: "MS", "code3": "MSR", label: "Montserrat", "number": "500" },
  { value: "MA", "code3": "MAR", label: "Morocco", "number": "504" },
  { value: "MZ", "code3": "MOZ", label: "Mozambique", "number": "508" },
  { value: "MM", "code3": "MMR", label: "Myanmar", "number": "104" },
  { value: "NA", "code3": "NAM", label: "Namibia", "number": "516" },
  { value: "NR", "code3": "NRU", label: "Nauru", "number": "520" },
  { value: "NP", "code3": "NPL", label: "Nepal", "number": "524" },
  { value: "NL", "code3": "NLD", label: "Netherlands (the)", "number": "528" },
  { value: "NC", "code3": "NCL", label: "New Caledonia", "number": "540" },
  { value: "NZ", "code3": "NZL", label: "New Zealand", "number": "554" },
  { value: "NI", "code3": "NIC", label: "Nicaragua", "number": "558" },
  { value: "NE", "code3": "NER", label: "Niger (the)", "number": "562" },
  { value: "NG", "code3": "NGA", label: "Nigeria", "number": "566" },
  { value: "NU", "code3": "NIU", label: "Niue", "number": "570" },
  { value: "NF", "code3": "NFK", label: "Norfolk Island", "number": "574" },
  { value: "MP", "code3": "MNP", label: "Northern Mariana Islands (the)", "number": "580" },
  { value: "NO", "code3": "NOR", label: "Norway", "number": "578" },
  { value: "OM", "code3": "OMN", label: "Oman", "number": "512" },
  { value: "PK", "code3": "PAK", label: "Pakistan", "number": "586" },
  { value: "PW", "code3": "PLW", label: "Palau", "number": "585" },
  { value: "PS", "code3": "PSE", label: "Palestine, State of", "number": "275" },
  { value: "PA", "code3": "PAN", label: "Panama", "number": "591" },
  { value: "PG", "code3": "PNG", label: "Papua New Guinea", "number": "598" },
  { value: "PY", "code3": "PRY", label: "Paraguay", "number": "600" },
  { value: "PE", "code3": "PER", label: "Peru", "number": "604" },
  { value: "PH", "code3": "PHL", label: "Philippines (the)", "number": "608" },
  { value: "PN", "code3": "PCN", label: "Pitcairn", "number": "612" },
  { value: "PL", "code3": "POL", label: "Poland", "number": "616" },
  { value: "PT", "code3": "PRT", label: "Portugal", "number": "620" },
  { value: "PR", "code3": "PRI", label: "Puerto Rico", "number": "630" },
  { value: "QA", "code3": "QAT", label: "Qatar", "number": "634" },
  { value: "MK", "code3": "MKD", label: "Republic of North Macedonia", "number": "807" },
  { value: "RO", "code3": "ROU", label: "Romania", "number": "642" },
  { value: "RU", "code3": "RUS", label: "Russian Federation (the)", "number": "643" },
  { value: "RW", "code3": "RWA", label: "Rwanda", "number": "646" },
  { value: "RE", "code3": "REU", label: "Réunion", "number": "638" },
  { value: "BL", "code3": "BLM", label: "Saint Barthélemy", "number": "652" },
  { value: "SH", "code3": "SHN", label: "Saint Helena, Ascension and Tristan da Cunha", "number": "654" },
  { value: "KN", "code3": "KNA", label: "Saint Kitts and Nevis", "number": "659" },
  { value: "LC", "code3": "LCA", label: "Saint Lucia", "number": "662" },
  { value: "MF", "code3": "MAF", label: "Saint Martin (French part)", "number": "663" },
  { value: "PM", "code3": "SPM", label: "Saint Pierre and Miquelon", "number": "666" },
  { value: "VC", "code3": "VCT", label: "Saint Vincent and the Grenadines", "number": "670" },
  { value: "WS", "code3": "WSM", label: "Samoa", "number": "882" },
  { value: "SM", "code3": "SMR", label: "San Marino", "number": "674" },
  { value: "ST", "code3": "STP", label: "Sao Tome and Principe", "number": "678" },
  { value: "SA", "code3": "SAU", label: "Saudi Arabia", "number": "682" },
  { value: "SN", "code3": "SEN", label: "Senegal", "number": "686" },
  { value: "RS", "code3": "SRB", label: "Serbia", "number": "688" },
  { value: "SC", "code3": "SYC", label: "Seychelles", "number": "690" },
  { value: "SL", "code3": "SLE", label: "Sierra Leone", "number": "694" },
  { value: "SG", "code3": "SGP", label: "Singapore", "number": "702" },
  { value: "SX", "code3": "SXM", label: "Sint Maarten (Dutch part)", "number": "534" },
  { value: "SK", "code3": "SVK", label: "Slovakia", "number": "703" },
  { value: "SI", "code3": "SVN", label: "Slovenia", "number": "705" },
  { value: "SB", "code3": "SLB", label: "Solomon Islands", "number": "090" },
  { value: "SO", "code3": "SOM", label: "Somalia", "number": "706" },
  { value: "ZA", "code3": "ZAF", label: "South Africa", "number": "710" },
  { value: "GS", "code3": "SGS", label: "South Georgia and the South Sandwich Islands", "number": "239" },
  { value: "SS", "code3": "SSD", label: "South Sudan", "number": "728" },
  { value: "ES", "code3": "ESP", label: "Spain", "number": "724" },
  { value: "LK", "code3": "LKA", label: "Sri Lanka", "number": "144" },
  { value: "SD", "code3": "SDN", label: "Sudan (the)", "number": "729" },
  { value: "SR", "code3": "SUR", label: "Suriname", "number": "740" },
  { value: "SJ", "code3": "SJM", label: "Svalbard and Jan Mayen", "number": "744" },
  { value: "SE", "code3": "SWE", label: "Sweden", "number": "752" },
  { value: "CH", "code3": "CHE", label: "Switzerland", "number": "756" },
  { value: "SY", "code3": "SYR", label: "Syrian Arab Republic", "number": "760" },
  { value: "TW", "code3": "TWN", label: "Taiwan", "number": "158" },
  { value: "TJ", "code3": "TJK", label: "Tajikistan", "number": "762" },
  { value: "TZ", "code3": "TZA", label: "Tanzania, United Republic of", "number": "834" },
  { value: "TH", "code3": "THA", label: "Thailand", "number": "764" },
  { value: "TL", "code3": "TLS", label: "Timor-Leste", "number": "626" },
  { value: "TG", "code3": "TGO", label: "Togo", "number": "768" },
  { value: "TK", "code3": "TKL", label: "Tokelau", "number": "772" },
  { value: "TO", "code3": "TON", label: "Tonga", "number": "776" },
  { value: "TT", "code3": "TTO", label: "Trinidad and Tobago", "number": "780" },
  { value: "TN", "code3": "TUN", label: "Tunisia", "number": "788" },
  { value: "TR", "code3": "TUR", label: "Turkey", "number": "792" },
  { value: "TM", "code3": "TKM", label: "Turkmenistan", "number": "795" },
  { value: "TC", "code3": "TCA", label: "Turks and Caicos Islands (the)", "number": "796" },
  { value: "TV", "code3": "TUV", label: "Tuvalu", "number": "798" },
  { value: "UG", "code3": "UGA", label: "Uganda", "number": "800" },
  { value: "UA", "code3": "UKR", label: "Ukraine", "number": "804" },
  { value: "AE", "code3": "ARE", label: "United Arab Emirates (the)", "number": "784" },
  { value: "UK", "code3": "GBR", label: "United Kingdom", "number": "826" },
  { value: "UM", "code3": "UMI", label: "United States Minor Outlying Islands (the)", "number": "581" },
  { value: "US", "code3": "USA", label: "United States of America (the)", "number": "840" },
  { value: "UY", "code3": "URY", label: "Uruguay", "number": "858" },
  { value: "UZ", "code3": "UZB", label: "Uzbekistan", "number": "860" },
  { value: "VU", "code3": "VUT", label: "Vanuatu", "number": "548" },
  { value: "VE", "code3": "VEN", label: "Venezuela (Bolivarian Republic of)", "number": "862" },
  { value: "VN", "code3": "VNM", label: "Viet Nam", "number": "704" },
  { value: "VG", "code3": "VGB", label: "Virgin Islands (British)", "number": "092" },
  { value: "VI", "code3": "VIR", label: "Virgin Islands (U.S.)", "number": "850" },
  { value: "WF", "code3": "WLF", label: "Wallis and Futuna", "number": "876" },
  { value: "EH", "code3": "ESH", label: "Western Sahara", "number": "732" },
  { value: "YE", "code3": "YEM", label: "Yemen", "number": "887" },
  { value: "ZM", "code3": "ZMB", label: "Zambia", "number": "894" },
  { value: "ZW", "code3": "ZWE", label: "Zimbabwe", "number": "716" },
  { value: "AX", "code3": "ALA", label: "Åland Islands", "number": "248" }
];

export default countryListAllIsoData;