const countries = [
    { value: "AF", name: "Afghanistan" },
    { value: "AX", name: "Åland Islands" },
    { value: "AL", name: "Albania" },
    { value: "DZ", name: "Algeria" },
    { value: "AS", name: "American Samoa" },
    { value: "AD", name: "Andorra" },
    { value: "AO", name: "Angola" },
    { value: "AI", name: "Anguilla" },
    { value: "AQ", name: "Antarctica" },
    { value: "AG", name: "Antigua and Barbuda" },
    { value: "AR", name: "Argentina" },
    { value: "AM", name: "Armenia" },
    { value: "AW", name: "Aruba" },
    { value: "AU", name: "Australia" },
    { value: "AT", name: "Austria" },
    { value: "AZ", name: "Azerbaijan" },
    { value: "BS", name: "Bahamas" },
    { value: "BH", name: "Bahrain" },
    { value: "BD", name: "Bangladesh" },
    { value: "BB", name: "Barbados" },
    { value: "BY", name: "Belarus" },
    { value: "BE", name: "Belgium" },
    { value: "BZ", name: "Belize" },
    { value: "BJ", name: "Benin" },
    { value: "BM", name: "Bermuda" },
    { value: "BT", name: "Bhutan" },
    { value: "BO", name: "Bolivia, Plurinational State of" },
    { value: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
    { value: "BA", name: "Bosnia and Herzegovina" },
    { value: "BW", name: "Botswana" },
    { value: "BV", name: "Bouvet Island" },
    { value: "BR", name: "Brazil" },
    { value: "IO", name: "British Indian Ocean Territory" },
    { value: "BN", name: "Brunei Darussalam" },
    { value: "BG", name: "Bulgaria" },
    { value: "BF", name: "Burkina Faso" },
    { value: "BI", name: "Burundi" },
    { value: "KH", name: "Cambodia" },
    { value: "CM", name: "Cameroon" },
    { value: "CA", name: "Canada" },
    { value: "CV", name: "Cape Verde" },
    { value: "KY", name: "Cayman Islands" },
    { value: "CF", name: "Central African Republic" },
    { value: "TD", name: "Chad" },
    { value: "CL", name: "Chile" },
    { value: "CN", name: "China" },
    { value: "CX", name: "Christmas Island" },
    { value: "CC", name: "Cocos (Keeling) Islands" },
    { value: "CO", name: "Colombia" },
    { value: "KM", name: "Comoros" },
    { value: "CG", name: "Congo" },
    { value: "CD", name: "Congo, the Democratic Republic of the" },
    { value: "CK", name: "Cook Islands" },
    { value: "CR", name: "Costa Rica" },
    { value: "CI", name: "Côte d'Ivoire" },
    { value: "HR", name: "Croatia" },
    { value: "CU", name: "Cuba" },
    { value: "CW", name: "Curaçao" },
    { value: "CY", name: "Cyprus" },
    { value: "CZ", name: "Czech Republic" },
    { value: "DK", name: "Denmark" },
    { value: "DJ", name: "Djibouti" },
    { value: "DM", name: "Dominica" },
    { value: "DO", name: "Dominican Republic" },
    { value: "EC", name: "Ecuador" },
    { value: "EG", name: "Egypt" },
    { value: "SV", name: "El Salvador" },
    { value: "GQ", name: "Equatorial Guinea" },
    { value: "ER", name: "Eritrea" },
    { value: "EE", name: "Estonia" },
    { value: "ET", name: "Ethiopia" },
    { value: "FK", name: "Falkland Islands (Malvinas)" },
    { value: "FO", name: "Faroe Islands" },
    { value: "FJ", name: "Fiji" },
    { value: "FI", name: "Finland" },
    { value: "FR", name: "France" },
    { value: "GF", name: "French Guiana" },
    { value: "PF", name: "French Polynesia" },
    { value: "TF", name: "French Southern Territories" },
    { value: "GA", name: "Gabon" },
    { value: "GM", name: "Gambia" },
    { value: "GE", name: "Georgia" },
    { value: "DE", name: "Germany" },
    { value: "GH", name: "Ghana" },
    { value: "GI", name: "Gibraltar" },
    { value: "GR", name: "Greece" },
    { value: "GL", name: "Greenland" },
    { value: "GD", name: "Grenada" },
    { value: "GP", name: "Guadeloupe" },
    { value: "GU", name: "Guam" },
    { value: "GT", name: "Guatemala" },
    { value: "GG", name: "Guernsey" },
    { value: "GN", name: "Guinea" },
    { value: "GW", name: "Guinea-Bissau" },
    { value: "GY", name: "Guyana" },
    { value: "HT", name: "Haiti" },
    { value: "HM", name: "Heard Island and McDonald Islands" },
    { value: "VA", name: "Holy See (Vatican City State)" },
    { value: "HN", name: "Honduras" },
    { value: "HK", name: "Hong Kong" },
    { value: "HU", name: "Hungary" },
    { value: "IS", name: "Iceland" },
    { value: "IN", name: "India" },
    { value: "ID", name: "Indonesia" },
    { value: "IR", name: "Iran, Islamic Republic of" },
    { value: "IQ", name: "Iraq" },
    { value: "IE", name: "Ireland" },
    { value: "IM", name: "Isle of Man" },
    { value: "IL", name: "Israel" },
    { value: "IT", name: "Italy" },
    { value: "JM", name: "Jamaica" },
    { value: "JP", name: "Japan" },
    { value: "JE", name: "Jersey" },
    { value: "JO", name: "Jordan" },
    { value: "KZ", name: "Kazakhstan" },
    { value: "KE", name: "Kenya" },
    { value: "KI", name: "Kiribati" },
    { value: "KP", name: "Korea, Democratic People's Republic of" },
    { value: "KR", name: "Korea, Republic of" },
    { value: "KW", name: "Kuwait" },
    { value: "KG", name: "Kyrgyzstan" },
    { value: "LA", name: "Lao People's Democratic Republic" },
    { value: "LV", name: "Latvia" },
    { value: "LB", name: "Lebanon" },
    { value: "LS", name: "Lesotho" },
    { value: "LR", name: "Liberia" },
    { value: "LY", name: "Libya" },
    { value: "LI", name: "Liechtenstein" },
    { value: "LT", name: "Lithuania" },
    { value: "LU", name: "Luxembourg" },
    { value: "MO", name: "Macao" },
    { value: "MK", name: "Macedonia, the former Yugoslav Republic of" },
    { value: "MG", name: "Madagascar" },
    { value: "MW", name: "Malawi" },
    { value: "MY", name: "Malaysia" },
    { value: "MV", name: "Maldives" },
    { value: "ML", name: "Mali" },
    { value: "MT", name: "Malta" },
    { value: "MH", name: "Marshall Islands" },
    { value: "MQ", name: "Martinique" },
    { value: "MR", name: "Mauritania" },
    { value: "MU", name: "Mauritius" },
    { value: "YT", name: "Mayotte" },
    { value: "MX", name: "Mexico" },
    { value: "FM", name: "Micronesia, Federated States of" },
    { value: "MD", name: "Moldova, Republic of" },
    { value: "MC", name: "Monaco" },
    { value: "MN", name: "Mongolia" },
    { value: "ME", name: "Montenegro" },
    { value: "MS", name: "Montserrat" },
    { value: "MA", name: "Morocco" },
    { value: "MZ", name: "Mozambique" },
    { value: "MM", name: "Myanmar" },
    { value: "NA", name: "Namibia" },
    { value: "NR", name: "Nauru" },
    { value: "NP", name: "Nepal" },
    { value: "NL", name: "Netherlands" },
    { value: "NC", name: "New Caledonia" },
    { value: "NZ", name: "New Zealand" },
    { value: "NI", name: "Nicaragua" },
    { value: "NE", name: "Niger" },
    { value: "NG", name: "Nigeria" },
    { value: "NU", name: "Niue" },
    { value: "NF", name: "Norfolk Island" },
    { value: "MP", name: "Northern Mariana Islands" },
    { value: "NO", name: "Norway" },
    { value: "OM", name: "Oman" },
    { value: "PK", name: "Pakistan" },
    { value: "PW", name: "Palau" },
    { value: "PS", name: "Palestinian Territory, Occupied" },
    { value: "PA", name: "Panama" },
    { value: "PG", name: "Papua New Guinea" },
    { value: "PY", name: "Paraguay" },
    { value: "PE", name: "Peru" },
    { value: "PH", name: "Philippines" },
    { value: "PN", name: "Pitcairn" },
    { value: "PL", name: "Poland" },
    { value: "PT", name: "Portugal" },
    { value: "PR", name: "Puerto Rico" },
    { value: "QA", name: "Qatar" },
    { value: "RE", name: "Réunion" },
    { value: "RO", name: "Romania" },
    { value: "RU", name: "Russian Federation" },
    { value: "RW", name: "Rwanda" },
    { value: "BL", name: "Saint Barthélemy" },
    { value: "SH", name: "Saint Helena, Ascension and Tristan da Cunha" },
    { value: "KN", name: "Saint Kitts and Nevis" },
    { value: "LC", name: "Saint Lucia" },
    { value: "MF", name: "Saint Martin (French part)" },
    { value: "PM", name: "Saint Pierre and Miquelon" },
    { value: "VC", name: "Saint Vincent and the Grenadines" },
    { value: "WS", name: "Samoa" },
    { value: "SM", name: "San Marino" },
    { value: "ST", name: "Sao Tome and Principe" },
    { value: "SA", name: "Saudi Arabia" },
    { value: "SN", name: "Senegal" },
    { value: "RS", name: "Serbia" },
    { value: "SC", name: "Seychelles" },
    { value: "SL", name: "Sierra Leone" },
    { value: "SG", name: "Singapore" },
    { value: "SX", name: "Sint Maarten (Dutch part)" },
    { value: "SK", name: "Slovakia" },
    { value: "SI", name: "Slovenia" },
    { value: "SB", name: "Solomon Islands" },
    { value: "SO", name: "Somalia" },
    { value: "ZA", name: "South Africa" },
    { value: "GS", name: "South Georgia and the South Sandwich Islands" },
    { value: "SS", name: "South Sudan" },
    { value: "ES", name: "Spain" },
    { value: "LK", name: "Sri Lanka" },
    { value: "SD", name: "Sudan" },
    { value: "SR", name: "Suriname" },
    { value: "SJ", name: "Svalbard and Jan Mayen" },
    { value: "SZ", name: "Swaziland" },
    { value: "SE", name: "Sweden" },
    { value: "CH", name: "Switzerland" },
    { value: "SY", name: "Syrian Arab Republic" },
    { value: "TW", name: "Taiwan, Province of China" },
    { value: "TJ", name: "Tajikistan" },
    { value: "TZ", name: "Tanzania, United Republic of" },
    { value: "TH", name: "Thailand" },
    { value: "TL", name: "Timor-Leste" },
    { value: "TG", name: "Togo" },
    { value: "TK", name: "Tokelau" },
    { value: "TO", name: "Tonga" },
    { value: "TT", name: "Trinidad and Tobago" },
    { value: "TN", name: "Tunisia" },
    { value: "TR", name: "Turkey" },
    { value: "TM", name: "Turkmenistan" },
    { value: "TC", name: "Turks and Caicos Islands" },
    { value: "TV", name: "Tuvalu" },
    { value: "UG", name: "Uganda" },
    { value: "UA", name: "Ukraine" },
    { value: "AE", name: "United Arab Emirates" },
    { value: "UK", name: "United Kingdom" },
    { value: "US", name: "United States" },
    { value: "UM", name: "United States Minor Outlying Islands" },
    { value: "UY", name: "Uruguay" },
    { value: "UZ", name: "Uzbekistan" },
    { value: "VU", name: "Vanuatu" },
    { value: "VE", name: "Venezuela, Bolivarian Republic of" },
    { value: "VN", name: "Viet Nam" },
    { value: "VG", name: "Virgin Islands, British" },
    { value: "VI", name: "Virgin Islands, U.S." },
    { value: "WF", name: "Wallis and Futuna" },
    { value: "EH", name: "Western Sahara" },
    { value: "YE", name: "Yemen" },
    { value: "ZM", name: "Zambia" },
    { value: "ZW", name: "Zimbabwe" },
];
const codes = {
    AF: "Afghanistan",
    AX: "Åland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia, Plurinational State of",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, the Democratic Republic of the",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Côte d'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea, Democratic People's Republic of",
    KR: "Korea, Republic of",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia, the former Yugoslav Republic of",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States of",
    MD: "Moldova, Republic of",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthélemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan, Province of China",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    UK: "United Kingdom",
    US: "United States",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela, Bolivarian Republic of",
    VN: "Viet Nam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
};
const currencies = ['GBP', 'EUR', 'USD'];
export default countries;
export { codes, currencies };
