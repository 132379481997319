import { PublicTrackingTable } from "components/PublicTrackingTable";
import React from "react";

const PublicTrackingModal = () => {
    return (
        <>
            <div className="ps-0 container text-start">
                <PublicTrackingTable />
            </div>
        </>
    );
};

export default PublicTrackingModal;
