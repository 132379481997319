export const testimonials = [
    {
        content:
            "საუკეთესო შეფასება მომსახურე-ოპერატორების ჯგუფს! წარმატებები! მიხარია რომ გიპოვეთ 👌 worth every penny ❤",
        name: "Ekaterine T.",
        src: "/images/client_3.jpg",
    },
    {
        content:
            "ძალიან კმაყოფილი ვარ თქვენი სერვისით, რამოდენიმე თვეა ვსარგეობ ამ სერვისით. ამანათები ჩავიდა დროზე ადრე, თანამშრომლებიიყვნენ ძალიან მზრუნველი და ყურადღებიანი. მადლობა",
        name: "Keti N.",
        src: "/images/client_1.jpg",
    },
    {
        content:
            "კომპანია რომელთანაც ვთანამშრომლობ 10წელზე მეტი.რომელიც დაკომპლექტებულია თანამშრომლებით,რომლებიც პასუხისმგებლობით ეკიდებიან დაკისრებულ ვალდებულებას. 😍😍😍",
        name: "Mariam K.",
        src: "/images/client_2.jpg",
    },
    {
        content: "Reliable and trustworthy service 👍",
        name: "Kristina K.",
        src: "/images/client_5.jpg",
    },
    {
        content: "greatest service. wonderful team.thanks everyone.",
        name: "Maya S.",
        src: "/images/client_6.jpg",
    },
    {
        content: "Good Service .. kmayopili var tqveni momsaxurebit ...",
        name: "Lika S.",
        src: "/images/client_7.jpg",
    },
    {
        content: "Thank you very much for your fast and quality service.",
        name: "Alex L.",
        src: "/images/client_8.jpg",
    },
];
